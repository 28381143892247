<template>
  <div class="container">
    <div class="header">
      <img src="../../assets/news/tt@2x.png" alt="" />
      <p>产品中心</p>
    </div>
    <div class="tabs">
      <span @click="routerIndex()">首页 > </span>
      <span>产品中心</span>
    </div>
    <div class="box">
      <div>
        <div class="contact">
          <div class="content">
            <img src="../../assets/index/fwrx.png" alt="" />
            <div class="font">咨询服务热线</div>
            <div class="number">18758021092</div>
            <div class="btn">免费获取产品方案</div>
          </div>
        </div>
      </div>

      <el-card>
        <div class="products">
          <div
            class="list"
            v-for="(item, i) in productList"
            :key="i"
          >
            <img  :src="item.productImage" alt="" />
            <div class="name">{{ item.productName }}</div>
            <div class="english">{{ item.productAlias }}</div>
            <div class="flex">
              <div class="btn1" @click="getOffer()">获得报价</div>
              <div @click="routerDetail(item, '产品详情')" class="btn2">
                了解详情
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="state.pageNum"
            :page-size="state.pageSize"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
// import img1 from "../../assets/product/cp1.png";
// import img2 from "../../assets/product/cp2.png";
// import img3 from "../../assets/product/cp3.png";
// import img4 from "../../assets/product/cp4.png";
// import img5 from "../../assets/product/cp5.png";
// import img6 from "../../assets/product/cp6.png";
// import img7 from "../../assets/product/cp2.png";
// import img8 from "../../assets/product/cp8.png";
// import img9 from "../../assets/product/cp9.png";
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  data() {
    return {
      typeIndex: 0,
      activeName: "first",
      groupIndex: 0,
      state:{
        pageNum: 1,
        pageSize:9,
      },

      total:9,
      productList:[],

    };
  },
  mounted(){
    this.requestProductList(this.state)
  },
  methods: {
    // 跳转到首页
    routerIndex() {
      this.$router.push("/index");
      this.$store.commit("changeIndex", 0);
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      this.state.pageSize = val;
      this.requestProductList(this.state);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.state.pageNum = val;
      this.requestProductList(this.state);
    },
     // 请求产品列表
     requestProductList(state) {
      var param = state
      this.$post("/web/product/pageList", param)
        .then((res) => {
          // 主要参数
          if (res.code == 1) {
            // console.log(res.data);
            this.productList=res.data.list;
            this.total = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 跳转到详情页
    routerDetail(item, name) {
      this.$store.commit("changeTheme", false);
      this.$router.push({
        name: name,
        query: {
          item,
        },
      });
      localStorage.setItem("productDetail", JSON.stringify(item));
    },
    // 获得报价
    getOffer() {
      this.$message.warning("详情请咨询客服");
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
}
.header img {
  width: 100%;
}
.header p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  color: white;
  font-size: 0.5rem;
}
// 导航条
.tabs {
  width: 100%;
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  line-height: 38px;
  font-size: 0.2rem;
  span:nth-child(1) {
    margin-left: 24px;
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(2) {
    color: #565656;
    cursor: pointer;
  }
}
.box {
  display: flex;
  margin: 0.5rem 1.6rem;
}
// 下方联系方式

.contact {
  width: 3rem;
  height: auto;
  margin-right: 0.18rem;
  background: #f5f5f5;
  // margin-top: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 10px 0;
  .content {
    width: 80%;
    background: #fff;
    margin: 10px 10%;
    padding: 10px 0;
    img {
      width: 0.8rem;
    }
    .font {
      font-size: 0.2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #272727;
      cursor: pointer;
      margin: 5px 0;
    }
    .num {
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #272727;
      cursor: pointer;
    }
    .btn {
      width: 80%;
      margin: 5px 10%;
      background: #127bf2;
      color: #fff;
      font-size: 0.15rem;
      white-space: nowrap;
      padding: 5px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
.el-card:nth-child(1) {
  width: 3rem;
  height: auto;
  margin-right: 0.18rem;
}
.el-menu-vertical-demo {
  background: #f5f5f5;
}
.el-menu {
  border: none;
}
.el-menu-item {
  background: #fff;
  padding: 0 !important;
  margin: 5px 0 10px;
  height: 36px;
  line-height: 36px;
  text-align: left;
  padding-left: 10px !important;
}
.el-card:nth-child(2) {
  width: 12.5rem;
  // height: 8rem;
  position: relative;
}

.products {
  display: flex;
  justify-content: space-around;
  padding: 0.25rem 0;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  .list {
    width: 30%;
    background: #f5f5f5;
    box-shadow: 0px 4px 12px 0px rgba(203, 203, 203, 0.5);
    margin-bottom: 0.5rem;
    text-align: center;
    min-height: 3.5rem;
    img {
      width: 3rem;
      height: 2rem;
      object-fit: contain;
    }
    .name {
      font-size: 0.25rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #565656;
      line-height: 0.5rem;
      cursor: pointer;
      margin-top: 0.1rem;
    }
    .english {
      cursor: pointer;
      font-size: 0.22rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #565656;
    }
    .flex {
      width: 3rem;
      display: flex;
      justify-content: space-around;
      margin: 0.2rem 0;
      .btn1 {
        width: 1rem;
        border-radius: 8px;
        border: 2px solid #fdb753;
        text-align: center;
        padding: 5px;
        color: #fdb753;
        font-size: 0.2rem;
        cursor: pointer;
      }
      .btn1:hover {
        background: #fdb753;
        cursor: pointer;
        color: #fff;
      }
      .btn2 {
        width: 1rem;
        border-radius: 8px;
        border: 2px solid #127bf2;
        text-align: center;
        padding: 5px;
        color: #127bf2;
        font-size: 0.2rem;
        cursor: pointer;
      }
      .btn2:hover {
        background: #127bf2;
        cursor: pointer;
        color: #fff;
      }
    }
  }
}

.products:nth-of-type(1) {
  padding-top: 0.15rem;
}

.pagination {
  position: absolute;
  bottom: 0.4rem;
  left: 50%;
  transform: translate(-50%);
}
</style>
